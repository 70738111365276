import { Col, Row, Space, Typography } from 'antd';
import Compact from 'antd/es/space/Compact';
import DragUpload from '@Components/BasicComponents/DragUpload';
import InfoTooltip from './InfoTooltip';

const {Text} = Typography;

const BulkUpload = ({
	handleUpload,
	handleRemove,
	value,
	accept,
	beforeUpload,
	error = {isOk: true},
	tooltip,
	title = null,
	required
}) => {

	return (
		<Compact block size="middle" direction='vertical'>
			<Row  align="middle" gap={10}>
				<Space>
					<h3>
						{title || 'Bulk upload'}
						{required && '*'}
					</h3>
					{tooltip && <InfoTooltip text={tooltip} />}
				</Space>
			</Row>
			<DragUpload
				listType="picture"
				value={value}
				className='bulk-upload__row'
				multiple
				beforeUpload={beforeUpload}
				handleUpload={handleUpload}
				accept={accept}
				onRemove={handleRemove}
				style={!error?.isOk && {borderColor: 'red'}}
			/>
			<Row align="middle">
				<Col span={16}>
					<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
				</Col>
			</Row>
		</Compact>
	);
};

export default BulkUpload;