import Links from '@Components/ComposedComponents/Links';
import Attachments from '@Components/ComposedComponents/Attachments';

export default function LinksAndAttachments (props) {

	const { disabled = false } = props;

	return (
		<>
			<h3>Links and attachments</h3>
			<Links {...props}/>
			{!disabled && <Attachments {...props}/>}
		</>
	);
}