import { Button, Card, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Link from '@Components/BasicComponents/Link';

export default function Links (props) {
	const { selector, ...otherProps } = props;
	const { links, editListItem, addToList, removeFromList, countryCode, setCountryCode } = selector();

	const handleChange = (id) => ({param, countryCode, value}) => editListItem({list: 'links', id, param, countryCode, value});
	const handleAdd = () => {
		const object = {id: new Date().getTime(), description: {}, ref: {}};

		addToList({list: 'links', object});
	};

	const handleRemove = (id) => () => removeFromList({list: 'links', id});

	return (
		<>
			{	links.length > 0 ?
				links.map(({ref, description, id}) =>
					<Link
						countryCode={countryCode}
						setCountryCode={setCountryCode}
						key={id}
						link={ref}
						description={description}
						handleChange={handleChange(id)}
						remove={handleRemove(id)}
						{...otherProps}
					/>
				) :
				<Card
					style={{ marginTop: 16 }}
				>
					This media has no links.
					{!otherProps.disabled && ' Add one with the button below.'}
				</Card>
			}
			{!otherProps.disabled &&
				<Divider
					style={{borderBlockStartColor: '#000', paddingInline: 50}}
					dashed
				>
					<Button
						size="large"
						shape="circle"
						icon={<PlusOutlined />}
						onClick={handleAdd}
					/>
				</Divider>
			}
		</>);
}