import MainInformation from '@Components/ComposedComponents/MainInformation';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function StreamingForm (props) {
	const navigate = useNavigate();

	return (
		<>
			<MainInformation
				hasTags={false}
				hasLocation
				hasStatus
				{...props}
			/>
			<br/><br/>
			<PublishingDates {...props}/>
			<br/><br/>
			<LinksAndAttachments {...props}/>
			<br/><br/>
			<Row justify="center">
				<Col>
					<Button
						onClick={() => navigate(-1)}
						type="default"
						size='large'
						style={{
							width: 200,
							padding: '5px 10px',
							borderRadius: '100px'
						}}
					>
						GO BACK
					</Button>
				</Col>
		</Row>
		</>
	);
}