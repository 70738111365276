import { Checkbox, Row, Space } from 'antd';


import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';
import Validate from '@Components/Validate';
import InfoTooltip from '@Components/BasicComponents/InfoTooltip';

import useSeoSync from '@/hooks/useSeoSync';

import {maxChars, maxKeywords } from '@/utils/errors/list';



export default function SEOInformation ({hasKeywords = true, selector, ...otherProps}) {
	const {syncSeo, setSyncSeo, seoTitle, seoDescription, seoKeywords, setValue, countryCode, setCountryCode} = useSeoSync({selector});

	const handleChange = (param) => {
		return ({countryCode, value}) => setValue({param, countryCode, value});
	};

	return (
		<>
			<Row justify="space-between" align="middle">
				<h3>SEO Information</h3>
				{
					!otherProps.disabled && <Space>
						<Checkbox checked={syncSeo} onChange={(e) => setSyncSeo(e.target.checked)}>
							Copy from main information
							{"  "}
						</Checkbox>
						<InfoTooltip text="An option to duplicate information from a primary or main entry to this form." />
					</Space>
				}
			</Row>
			<Validate requirements={[maxChars(60, {isWarning: true})]}>
				<MultiLanguageInput
					title="SEO title (max. 60 characters)"
					value={seoTitle}
					onChange={handleChange('seoTitle')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					disabled={syncSeo}
					tooltip="The title of the media content optimised for search engines. It should be concise, descriptive, and include relevant keywords to improve search rankings."
					{...otherProps}
				/>
			</Validate>

			<br />
			<Validate requirements={[maxChars(160, {isWarning: true})]}>
				<MultiLanguageInput
					title="SEO description (max. 160 characters)"
					textArea
					value={seoDescription}
					onChange={handleChange('seoDescription')}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					disabled={syncSeo}
					tooltip="A brief summary of the media content, designed to appear in external search engine results. This should highlight key information and attract users to click."
					{...otherProps}
				/>
			</Validate>
			{hasKeywords &&
				<>
					<br />
					<Validate requirements={[maxKeywords(15)]}>
						<MultiLanguageInput
							title="SEO keywords (max. 15 keywords)"
							value={seoKeywords}
							countryCode={countryCode}
							setCountryCode={setCountryCode}
							onChange={handleChange('seoKeywords')}
							tooltip="A list of specific keywords or phrases related to the content. These help search engines categorise and index the media for better discoverability in the different external search engine platforms."
							{...otherProps}
						/>
					</Validate>
				</>
			}
		</>

	);
}