import { useMemo } from 'react';
import { useSelector} from 'react-redux';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import BasicLabel from '@Components/BasicComponents/BasicLabel';

import { Row, Input, Select, Space, Typography } from 'antd';
import {CheckOutlined } from '@ant-design/icons';
import InfoTooltip from './InfoTooltip';
const { TextArea } = Input;
const { Option } = Select;
const { Compact } = Space;
const {Text} = Typography;

export default function MultiLanguageInput ({
	textArea = false,
	title,
	value: values,
	onChange,
	countryCode,
	setCountryCode,
	error = {isOk: true},
	tooltip = null,
  required = false,
	...otherProps
}) {
	const Tag = useMemo(() => textArea ? TextArea : Input, [textArea]);
	const params = useMemo(() => textArea ? {autoSize:{ minRows: 3, maxRows: 5 } } : {}, [textArea]);
	const {languages} = useSelector(mediaSettingsSelector);

	const handleChangeText = (value) => {
		onChange({countryCode, value});
	};

	return (
		<Compact block size="middle" direction='vertical'>
			<Row justify="space-between" align="middle">
				<BasicLabel
					title={title}
					tooltip={tooltip}
					required={required}
				/>
				<Space>
					<InfoTooltip
						text="To indicate and define the language in which the media content is presented. English is mandatory for the required fields."
						style={{marginRight: 20}}
					/>
					<Select
						value={countryCode}
						style={{ width: 150 }}
						onChange={setCountryCode}
						loading={languages.loading}
					>
						{
							languages.data?.map(({id, nameIso}) =>
								<Option key={id} value={id}>
									<Space>
										{nameIso}
										{values?.[id] &&  values?.[id] !== '' && <CheckOutlined />}
									</Space>
								</Option>)
						}
					</Select>
				</Space>
			</Row>
			<br/>
			<Tag
				title={title}
				key={`${title}-${countryCode}`}
				value={values && values[countryCode]}
				onChange={(e) => handleChangeText(e.target.value)}
				status={error?.show && error.type}
				{...params}
				{...otherProps}
			/>
			<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
		</Compact>
	);
}