import { Col, Row, Select, Space, Typography } from 'antd';
const {Text} = Typography;

import BasicLabel from '@Components/BasicComponents/BasicLabel';
import Compact from 'antd/es/space/Compact';

export default function BasicSelect ({
	title,
	value,
	onChange,
	options,
	error = {isOk: true},
	tooltip = null,
	required = false,
	compact = false,
	style,
	...rest
}) {

	if (compact) {
		return (
			<Compact block size="middle" direction='vertical'>
				<BasicLabel
					title={title}
					tooltip={tooltip}
					required={required}
				/>
				<br/>
				<Space size="middle" direction='vertical' style={{width: '100%'}}>
					<Select
						title={title}
						style={{...style, width: 200 }}
						value={value}
						onChange={onChange}
						options={options}
						status={error?.show && error.type}
						{...rest}
					/>
				</Space>
				<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
			</Compact>
		);
	}
	return (
		<Col span={24} >
			<Row align="middle">
				<Col span={8} lg={12} xl={10} xxl={8}>
					<BasicLabel
						title={title}
						tooltip={tooltip}
						required={required}
					/>
				</Col>
				<Col  span={16} lg={12} xl={14} xxl={16}>
					<Select
						title={title}
						style={{...style, width: "100%" }}
						value={value}
						onChange={onChange}
						options={options}
						status={error?.show && error.type}
						{...rest}
					/>
				</Col>
			</Row>
			<Row align="middle">
				<Col offset={8} span={16}>
					<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
				</Col>
			</Row>
		</Col>
		
	);
}