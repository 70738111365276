import { useSelector} from 'react-redux';

import { Col, Row, Space, Grid } from 'antd';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import BasicSelect from '@Components/BasicComponents/BasicSelect';
import Validate from '@Components/Validate';
import BasicInput from '@Components/BasicComponents/BasicInput';

import { required } from '@/utils/errors/list';
import { transformMediaType } from '@/utils/basics';
import { uploadedAttachments } from '@/utils/basics';


const { Compact } = Space;
const { useBreakpoint } = Grid;

let timeoutId = null;

const includeGetEpid = ['EPV_AUDIO'];

export default function MediaDefinition ({exclude, edit = false, selector, disabled, ...otherProps}) {
	const {
		mediaType,
		category,
		epId,
		files,
		attachments,
		setValue,
		setInitialState,
		setEpIdValue,
		getEpId,
		owner
	} = selector();
	const { mediaTypes, contentOwners } = useSelector(mediaSettingsSelector);
	const { xl } = useBreakpoint();

	console.log(otherProps);
	

	const handleMediaType = (value) => {
		if (mediaType) {
			setInitialState({owner});
		}
		setValue({param: 'mediaType', value});
	};

	const handleCategory = (value) => {
		setValue({param: 'category', value});
		if (!edit && includeGetEpid.includes(mediaType) && !files.length && !uploadedAttachments(attachments)) {
			getEpId({
				data: {category: value},
				type: transformMediaType(mediaType)
			});
		}
	};

	const handleOwner = (value) => setValue({param: 'owner', value});

	const handleId = (value) => {
		setEpIdValue(value);

		if (timeoutId !== null) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout( () =>
			getEpId({
				data: {category, epId: value },
				type: transformMediaType(mediaType)
			})
		, 1000);
	};

	const filterTypes = (types) => {
		if (!exclude || exclude?.length === 0) {
			return types;
		}

		return types.filter( type => !exclude.includes(type.value));
	};

	return (
		<Compact block size="middle" direction='vertical'>
			<Row align="top" gutter={[24, 12]}>
				<Col md={24} lg={12} xl={8}>
					<Row>
						<Validate requirements={[required]}>
							<BasicSelect
								title='Content owner'
								value={owner}
								onChange={handleOwner}
								options={contentOwners.data}
								required
								tooltip='It is the European Parliament Unit or team that owns and is responsible for this media content in the Multimedia Centre.'
								disabled={disabled}
								{...otherProps}
							/>
						</Validate>
					</Row>
				</Col>
				<Col md={24} lg={12} xl={8}>
					<Row justify="start">
						<Validate requirements={[required]}>
							<BasicSelect
								title='Media Type'
								value={mediaType}
								onChange={handleMediaType}
								options={filterTypes(mediaTypes.data)}
								disabled={edit || disabled}
								required
								tooltip='This is the technical classification of the different media that exist in the Multimedia Centre based on the nature or format of the main file to be viewed or published.'
								{...otherProps}
							/>
						</Validate>
					</Row>
				</Col>
				<Col md={24} lg={12} xl={8}>
					<Row justify="start">
						<Validate requirements={[required]}>
							<BasicSelect
								title='Category'
								value={category}
								onChange={handleCategory}
								options={mediaTypes.categories[mediaType]}
								disabled={!mediaType || disabled}
								required
								tooltip='It is the editorial classification of the different media that exist in the Multimedia Centre and that belong to a media type.'
								{...otherProps}
							/>
						</Validate>
					</Row>
				</Col>
				{xl && <><br/> <br/></>}
				<Col span={24} lg={12} xl={24} xxl={16}>
					<Row justify="start">
						<Validate requirements={[required]} error={epId.error && {isOk: false, text: epId.error.message}}>
							<BasicInput
								title="Reference ID"
								value={epId.data}
								onChange={handleId}
								disabled={!category || files.length || uploadedAttachments(attachments) || edit || disabled}
								loading={epId.loading}
								required
								inline={!xl}
								tooltip='It is the unique identifier of the media content to be able to locate and distinguish it from other media that may be similar in the Multimedia Centre.'
								{...otherProps}
							/>
						</Validate>
					</Row>
				</Col>
			</Row>
			<br />
		</Compact>
	);
}