import { useSelector} from 'react-redux';

import { Col, Row, Typography, Select, Space } from 'antd';
import Compact from 'antd/es/space/Compact';
import {CheckOutlined } from '@ant-design/icons';
const { Option } = Select;
const {Text} = Typography;

import DragUpload from '@Components/BasicComponents/DragUpload';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';
import InfoTooltip from './InfoTooltip';


export default function MultiLanguageUpload({handleUpload, handleRemove, value, accept, beforeUpload, countryCode, setCountryCode, error = {isOk: true}, tooltip, title = null, type = null, component = null, required= false}){
	const {languages} = useSelector(mediaSettingsSelector);

	const values = type ? value?.[type] || {} : value;

	return (
		<Compact block size="middle" direction='vertical'>
			<Row justify="space-between" align="middle">
				<div style={{width: '49%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
					<Space>
						<h3>
							{title || 'Bulk upload'}
							{required && '*'}
						</h3>
						{tooltip && <InfoTooltip text={tooltip} />}
					</Space>
					{component}
				</div>
				<Space>
					<InfoTooltip
						text="To indicate and define the language in which the media content is presented. English is mandatory for the required fields."
						style={{marginRight: 20}}
					/>
					<Select
						value={countryCode}
						style={{ width: 150 }}
						onChange={setCountryCode}
						loading={languages.loading}
					>
						{
							languages.data?.map(({id, nameIso}) =>
								<Option key={id} value={id}>
									<Space>
										{nameIso}
										{values?.[id] &&  values?.[id].length > 0 && <CheckOutlined />}
									</Space>
								</Option>)
						} 
					</Select>
				</Space>
			</Row>
			<DragUpload
				listType="picture"
				value={values[countryCode] || []}
				className='bulk-upload__row'
				multiple
				beforeUpload={beforeUpload}
				handleUpload={({file}) =>handleUpload({file, countryCode, type})}
				accept={accept}
				onRemove={(file) => handleRemove(file, countryCode, type)}
				style={!error?.isOk && {borderColor: 'red'}}
			/>
			<Row align="middle">
				<Col span={16}>
					<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
				</Col>
			</Row>
		</Compact>
	);
}