import dayjs from 'dayjs';
import { Col, DatePicker, Row, Typography } from 'antd';
const {Text} = Typography;

import { utcToLocalTime } from '@/utils/dates';

import BasicLabel from '@Components/BasicComponents/BasicLabel';

export default function BasicDatePicker ({
	title,
	value,
	onChange,
	error = {isOk: true},
	minDate,
	maxDate,
  tooltip = null,
  required = false,
	...otherProps
}) {
	const disabledDate = (current) => {
		if (minDate) {
			return dayjs(minDate) && current < dayjs(minDate).endOf('day');
		}

		if (maxDate) {
			return dayjs(maxDate) && current > dayjs(maxDate).endOf('day');
		}

		return '';
	};

	return (
		<>
			<Row align="middle">
				<Col span={8}>
					<BasicLabel
						title={title}
						tooltip={tooltip}
						required={required}
					/>
				</Col>
				<Col span={16}>
					<DatePicker
						value={value ? utcToLocalTime(value) : ''}
						format="DD-MM-YYYY HH:mm"
						showTime
						onChange={onChange}
						status={error?.show && error.type}
						disabledDate={disabledDate}
						{...otherProps}
					/>
				</Col>
			</Row>
			<Row align="middle">
				<Col offset={8} span={16}>
					<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
				</Col>
			</Row>
		</>
	);
}