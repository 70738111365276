import MainInformation from '@Components/ComposedComponents/MainInformation';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';
import CopyrightInformation from '@Components/ComposedComponents/CopyrightInformation';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import AudioBulkUpload from '@Components/ComposedComponents/AudioBulkUpload';
import Transcript from '@Components/ComposedComponents/Transcript';
import UploadButtons from '@Components/ComposedComponents/UploadButtons';

export default function AudioForm (props) {

	return (
		<>
			<MainInformation {...props}/>
			<br/><br/>
			<SEOInformation {...props}/>
			<br/><br/>
			<PublishingDates {...props}/>
			<br/><br/>
			<CopyrightInformation {...props}/>
			<br/><br/>
			<LinksAndAttachments {...props}/>
			<br/><br/>
			<AudioBulkUpload {...props}/>
			<br/><br/>
			<Transcript {...props}/>
			<br/><br/>
			<UploadButtons {...props}/>
		</>
	);
}