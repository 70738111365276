import { getSetting } from "../utils/settings";

const urlPrefix = getSetting('URL_PREFIX');

export const HOME = urlPrefix +'/';
export const LOGIN = urlPrefix + '/login';

export const FORGOT_PASSWORD = urlPrefix + '/forgot-password';
export const EVENTS = urlPrefix + '/event-list';
export const EVENT_DETAILS = urlPrefix + '/event';
export const TAGS = urlPrefix + '/tags';


export const MEDIA = urlPrefix + '/media';
export const UPLOAD_MEDIA = MEDIA +'/upload';
export const EDIT_MEDIA = MEDIA +'/edit';
export const RELEVANT_MEDIA = MEDIA + '/most-relevant';

export const TOPICS = urlPrefix + '/topics';
export const LIST_TOPIC = TOPICS + '/list';
export const EDIT_TOPIC = TOPICS + '/edit';
export const CREATE_TOPIC = TOPICS + '/create';
export const SPOTLIGHTED_TOPICS = TOPICS + '/spotlighted';
export const FEATURED_TOPICS = TOPICS + '/featured';
export const CONTENT_TOPICS = TOPICS + '/content';
export const TRENDING_TOPICS = TOPICS + '/trending';

export const OTHERS = urlPrefix + '/others';
export const HOMEPAGE_MEDIA_CARDS = OTHERS + '/homepage-media-cards';
export const HOMEPAGE_LIVE_STREAMING = OTHERS + '/homepage-live-streaming';
export const SERVICE_MESSAGE = OTHERS + '/service-message';
export const PROMOTIONAL_BANNER = OTHERS + '/promotional-banner';

export const DASHBOARDS = urlPrefix + '/monitoring-dashboards';
export const MONITORING_DASHBOARDS = DASHBOARDS + '/list';
export const DASHBOARD_FILE_PROCESSING = DASHBOARDS + '/file-processing';
export const DASHBOARD_MOST_VISITED = DASHBOARDS + '/most-visited';
export const DASHBOARD_MOST_DOWNLOADED = DASHBOARDS + '/most-downloaded';

export const USERS = urlPrefix + '/users';
export const TASKS = urlPrefix + '/tasks';

export const MULTILINGUAL_DICTIONARIES = urlPrefix + '/multilingual-dictionaries';
export const SYNONYMS = MULTILINGUAL_DICTIONARIES + '/synonyms';
export const HYPONYMS = MULTILINGUAL_DICTIONARIES + '/hyponyms';
export const ACRONYMS = MULTILINGUAL_DICTIONARIES + '/acronyms';
export const KEYWORDS_EXPANSION = MULTILINGUAL_DICTIONARIES + '/keywords-expansion';
export const ALIASES = MULTILINGUAL_DICTIONARIES + '/aliases';
export const AUTOCORRECTION_WORDS = MULTILINGUAL_DICTIONARIES + '/autocorrection-words';