import { Link } from 'react-router-dom';
import * as ROUTES from '@Router/routes';

export default  [
	{
		key: 'media',
		label: 'Media',
		children: [
			{
				key: 'upload-media',
				label: (<Link to={ROUTES.UPLOAD_MEDIA}>Upload a new media</Link>),
			},
			{
				key: 'media-list',
				label: (<Link to={ROUTES.MEDIA}>View all media</Link>)
			},
			{
				key: 'most-relevant-media',
				label: (<Link to={ROUTES.RELEVANT_MEDIA}>Most relevant media</Link>)
			},
		],
	},
	{
		key: 'topics',
		label: 'Topics',
		children: [
			{
				key: 'create-topic',
				label: (<Link to={ROUTES.CREATE_TOPIC}>Create a new topic</Link>),
			},
			{
				key: 'all-topics',
				label: (<Link to={ROUTES.LIST_TOPIC}>View all topics</Link>),
			},
			{
				key: 'spotlighted-topics',
				label: (<Link to={ROUTES.SPOTLIGHTED_TOPICS}>Spotlighted topic</Link>),
			},
			{
				key: 'featured-topics',
				label: (<Link to={ROUTES.FEATURED_TOPICS}>Featured topics</Link>),
			},
			{
				key: 'content-topics',
				label: (<Link to={ROUTES.CONTENT_TOPICS}>Content topics</Link>),
			},
			{
				key: 'trending-topics',
				label: (<Link to={ROUTES.TRENDING_TOPICS}>Trending topics</Link>),
			},
		]
	},
	{
		key: 'events',
		label: (<Link to={ROUTES.EVENTS}>Events</Link>),
	},
	{
		key: 'tags',
		label: (<Link to={ROUTES.TAGS}>Tags</Link>),
	},
	{
		key: 'others',
		label: 'Others',
		children: [
			{
				key: 'homepage-media-cards',
				label: (<Link to={ROUTES.HOMEPAGE_MEDIA_CARDS}>Homepage media cards</Link>),
			},
			{
				key: 'homepage-live-streaming',
				label: (<Link to={ROUTES.HOMEPAGE_LIVE_STREAMING}>Homepage live streaming section</Link>)
			},
			{
				key: 'service-message',
				label: (<Link to={ROUTES.SERVICE_MESSAGE}>Service message</Link>),
			},
			{
				key: 'promotional-banner',
				label: (<Link to={ROUTES.PROMOTIONAL_BANNER}>Promotional banner</Link>),
			},
		],
	},
	{
		key: 'monitoring-dashboard',
		label: (<Link to={ROUTES.MONITORING_DASHBOARDS}>Monitoring dashboards</Link>),
	},
	{
		key: 'tasks',
		label: (<Link to={ROUTES.TASKS}>Tasks</Link>),
	},
	{
		key: 'users',
		label: (<Link to={ROUTES.USERS}>Users</Link>),
	},
	{
		key: 'multilingual-dictionaries',
		label: 'Multilingual dictionaries',
		children: [
			{
				key: 'synonyms',
				label: (<Link to={ROUTES.SYNONYMS}>Synonyms</Link>),
			},
			{
				key: 'hyponyms',
				label: (<Link to={ROUTES.HYPONYMS}>Hyponyms</Link>),
			},
			{
				key: 'acronyms',
				label: (<Link to={ROUTES.ACRONYMS}>Acronyms</Link>),
			},
			{
				key: 'keywords-expansion',
				label: (<Link to={ROUTES.KEYWORDS_EXPANSION}>Keywords expansion</Link>),
			},
			{
				key: 'aliases',
				label: (<Link to={ROUTES.ALIASES}>Aliases</Link>),
			},
			{
				key: 'auto-correction-words',
				label: (<Link to={ROUTES.AUTOCORRECTION_WORDS}>Autocorrection words</Link>),
			},
		]
	}
];