import { Col, Input, Row, Space, Spin, Typography } from 'antd';
import Compact from 'antd/es/space/Compact';
const {Text} = Typography;

import BasicLabel from '@Components/BasicComponents/BasicLabel';

export default function BasicInput ({
	title,
	value,
	onChange,
	error = {isOk: true},
	inline = false,
	loading = false,
	tooltip = null,
  required = false,
	...rest
}) {

	function handleChange(e) {
		onChange(e.target.value);
	}

	if (inline) {
		return (
			<Col span={24}>
				<Row align="middle">
					<Col span={8} lg={12} xl={10} xxl={8}>
						<BasicLabel
							title={title}
							tooltip={tooltip}
							required={required}
						/>
					</Col>
					<Col span={16} lg={12} xl={10} xxl={8}>
						<Spin spinning={loading}>
							<Input
								value={value}
								onChange={handleChange}
								style={{ width: "100%" }}
								status={error?.show && error.type}
								{...rest}
							/>
						</Spin>
					</Col>
				</Row>
				<Row align="middle">
					<Col offset={8} span={16}>
						<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
					</Col>
				</Row>
			</Col>
		);
	}
	return (
		<Compact block size="middle" direction='vertical'>
			<BasicLabel
				title={title}
				tooltip={tooltip}
				required={required}
			/>
			<br/>
			<Space size="middle" direction='vertical' style={{width: '100%'}}>
				<Input
					title={title}
					value={value}
					onChange={handleChange}
					status={error?.show && error.type}
					{...rest}
				/>
			</Space>
			<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
		</Compact>
	);
}