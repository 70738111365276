import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Select, Space, Table } from 'antd';
import Icon, { EyeOutlined } from '@ant-design/icons';

import Edit from '@Components/icons/Edit';
import Delete from '@Components/icons/Delete';
import ExternalLink from '@Components/icons/ExternalLink';

import { deleteItem } from '@Redux/mediaList/mediaListFetchers';

import * as ROUTES from '@Router/routes';

import { localTimeFormated } from '@/utils/dates';
import { getPreview } from '@/utils/settings';

const orderTypes = [
	{ label: 'Relevancy', value: 'relevancy:asc' },
	{ label: 'Event Date: newest', value: 'mediaDate:desc' },
	{ label: 'Event Date: oldest', value: 'mediaDate:asc' },
	{ label: 'Last Update: newest', value: 'modified:desc' },
	{ label: 'Last Update: oldest', value: 'modified:asc' },
];

const buttonStyles = { fontSize: '16px', display: 'flex', alignItems: 'center' };

export default function MediaTable ({data, loading, sort, setSort, getListData, type}) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [epId, setEpId] = useState(null);

	const dispatch = useDispatch();

	const columns = [
		{
			title: 'Reference ID',
			dataIndex: 'epId',
			key: 'epId',
			width: 200,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			style: { wordWrap: 'break-word', wordBreak: 'break-word' }
		},
		{
			title: 'Event Date',
			dataIndex: 'eventDate',
			key: 'eventDate',
			render: (_, item) => {
				return localTimeFormated(item.eventDate);
			},
			width: 150,
		},
		{
			title: 'Last Update',
			dataIndex: 'modifiedDate',
			key: 'modifiedDate',
			render: (_, item) => {
				return localTimeFormated(item.modifiedDate);
			},
			width: 150,
		},
		{
			title: <label  style={{width: '100%'}}>Order by: <Select options={orderTypes} value={sort} onChange={setSort} size='Large' style={{width: 170}}/></label>,
			key: 'action',
			width: 300,
			render: (_, element) => (
				<Space size="middle">
					{type === "streaming" ?
						<Button
							style={buttonStyles}
							href={`${ROUTES.MEDIA}/${type}s/${element.epId}`}
							type="primary"
						>
							<Icon component={EyeOutlined} color='black'  />
							View
						</Button> :
						<Button
							style={buttonStyles}
							href={`${ROUTES.EDIT_MEDIA}/${type}/${element.epId}`}
							type="primary"
						>
							<Icon component={Edit} color='black'  />
							Edit
						</Button>
					}
					<Button
						onClick={() => handleDelete(element.epId)}
						style={buttonStyles}
						danger
					>
						<Icon component={Delete} color='black'  />
						Delete
					</Button>
					<Button
						href={getPreview(type, element.epId)}
						target='_blank'
						style={{paddingInline: 5}}
					>
						<Icon component={ExternalLink} color='black'  />
					</Button>
				</Space>
			),
		},
	];

	const handleDelete = (epId) => {
		setEpId(epId);
		setIsModalOpen(true);
	};

	const deleteEntry = async () => {
		await dispatch(deleteItem({epId, type}));
		await getListData();

		setEpId(false);
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setEpId(false);
		setIsModalOpen(false);
	};

	return (
		<>
			<Modal title="Confirm delete" centered open={isModalOpen} onOk={deleteEntry} onCancel={handleCancel}>
				<p>Are you sure that you want to delete the file with epId {epId}?</p>
			</Modal>
			<Table
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={false}
				rowKey="key"
			/>
		</>
	);
}