
const settings = {
    'pro' : {
        'REACT_APP_EPMP_BASE_URL': 'https://api.multimedia.europarl.europa.eu/o/epmp-frontend-rest/v1.0',
        'REACT_APP_BASE_URL' : 'https://mp-cms-backend.ep-lavinia.eu/o/epmp-cms-rest/v1.0',
        'PREVIEW': 'https://multimedia.europarl.europa.eu/en/',
        'URL_PREFIX' :'',
        'ibmS3Bucket' : 'cos-es-mp-temporal-prod',
        'old_cms_url' : 'https://mp-cms.ep-lavinia.eu'
    },
    'pre' : {
        'REACT_APP_EPMP_BASE_URL': 'https://mp-api-test.ep-lavinia.eu/o/epmp-frontend-rest/v1.0',
        'REACT_APP_BASE_URL' : 'https://mp-cms-backend-test.ep-lavinia.eu/o/epmp-cms-rest/v1.0',
        'PREVIEW': 'https://multimediapp.europarl.europa.eu/en/',
        'URL_PREFIX' :'',
        'ibmS3Bucket' : 'cos-es-mp-temporal-test',
        'old_cms_url' : 'https://cms.multimediapp.europarl.europa.eu'
    },
    'local': {
        'REACT_APP_EPMP_BASE_URL': 'https://mp-api-test.ep-lavinia.eu/o/epmp-frontend-rest/v1.0',
        'REACT_APP_BASE_URL' : 'https://mp-cms-backend-test.ep-lavinia.eu/o/epmp-cms-rest/v1.0',
        'PREVIEW': 'https://multimediapp.europarl.europa.eu/en/',
        'URL_PREFIX' :'',
        'ibmS3Bucket' : 'cos-es-mp-temporal-test',
        'old_cms_url' : 'https://cms.multimediapp.europarl.europa.eu'
    }
};



export function getEnvironment() {
    const host = window.location.host;
    const patterns = {
        'multimediapp' : 'pre',
        '-test' : 'pre',
        'localhost' : 'local'
    };

    for(let i in patterns){
        if(host.indexOf(i) !== -1) return patterns[i];
    }

    return 'pro';
}

export function getSetting(name){
    let env = getEnvironment();
    return (typeof settings[env][name] !== 'undefined')? settings[env][name]  : null;
}

export function getPreview(type, epId) {
    let env = getEnvironment();

    if(type === 'streaming') type = 'webstreaming';

    return (typeof settings[env]['PREVIEW'] !== 'undefined')? `${settings[env]['PREVIEW']}${type}/cms_${epId}`  : null;
}

export function parseRelativeUrl(url){
    let urlPrefix = getSetting('URL_PREFIX');
    return urlPrefix + url;
}