export function areSameObject (obj1, obj2) {
	return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function isArray (value) {
	if (isObject(value) && value?.length) {
		return true;
	}

	return false;
}

export function isObject (value) {
	if (typeof value === 'object' && value?.length !== 0 && !value?.length) {
		return true;
	}
	return false;
}

export function prepareTagList (list) {
	return list.map(item => { return {label: item.title, value: item.id};});
}

export function prepareList (list) {
	return list.map(item => { return {label: item.nameIso, value: item.id};});
}

export function prepareMeiaTypesList (list) {
	return list.map(item => {
		if (item.nameLocal) {
			return {
				id: item.id,
				label: item.nameLocal,
				value: item.nameIso
			};
		}

		return {
      label: item.nameIso,
      value: item.id
    };
	});
}

export function prepareMediaTypes (list) {
	const types = prepareMeiaTypesList(list);
	const categories= {};

	list.forEach( type  => {
		if(type?.subOptionsLists) {
			const {nameIso, subOptionsLists, nameLocal, id} = type;
			categories[nameLocal ? nameIso : id] = prepareMeiaTypesList(subOptionsLists);
		}
	});

	return {types, categories};

}

export function prepareCoverages (list) {
  const obj = {};

	list.forEach(({id, nameIso}) => {
    obj[id] = nameIso;
  });

	return obj;
}

export function transformMediaType (mediaType) {
	if (mediaType === 'EPV_AUDIO') {
		return 'audio';
	}
	if (mediaType === 'EPV_AUDIO_PODCASTS') {
		return 'podcast';
	}
	if (mediaType === 'EPV_INFOGRAPHICS') {
		return 'infographic';
	}
}

export function uploadedAttachments(attachments) {
	let hasAttachments = false;
	for( let i = 0; i<attachments.length && !hasAttachments; i++) {
		hasAttachments = attachments[i].ref && Object.entries(attachments[i].ref)?.length > 0;
	}
	return hasAttachments;
}

export const objectNotEmpty = (obj) => {
	return Object.entries(obj).length > 0;
};

export const notAllObjectValuesAreEmpty = (obj) => {
	if(!objectNotEmpty(obj)) return false;

	for( let key in obj ) {
		if(obj[key] && obj[key] !== '') return true;
	}

	return false;
};
