import { useCallback } from 'react';
import { Button, Card } from 'antd';

import Validate from '@Components/Validate';

import { isLink } from '@/utils/errors/list';

import MultiLanguageInput from './MultiLanguageInput';

export default function Link ({handleChange, link, description, remove, countryCode, setCountryCode, ...otherProps}) {
	const onChange = useCallback(
		(param) => {
			return ({countryCode, value}) => handleChange({param, countryCode, value});
		}, []);

	return (
		<Card 
			style={{ marginTop: 16 }}
		>
			<Validate requirements={[isLink]}>
				<MultiLanguageInput
					title="Link"
					value={link}
					onChange={onChange('ref')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					tooltip="A URL related to the media content, such as a reference to the original source or related web pages."
					{...otherProps}
				/>
			</Validate>

			<br/>
			<MultiLanguageInput
				title="Descriptive text"
				value={description}
				onChange={onChange('description')}
				style={{marginVertical: 10}}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
				tooltip="An alternative description of the link or attachment that summarises the referral information."
				{...otherProps}
			/>
			<br/>
			{!otherProps.disabled &&
				<Button type="link" size="small" danger onClick={remove}>Remove</Button>
			}
		</Card>
	);
}