import { getLinksAndAttachments, transformAttachments, transformFiles, transformInfographicFiles, transformLinks, transformMultilanguageArrayToObject } from "./functions";

export function transformFetchedData (data, emptyLinks = false) {
    const {links, attachments} = getLinksAndAttachments(data.attachments);

    return {
        mediaType: data?.definitions?.mediaType || null,
        category: data?.definitions?.category || null,
        epId: {
            loading: false,
            error: null,
            data: data?.definitions?.epId || '',
        },
        eventId: data.eventId || '',
        title: data?.title || {},
        description: data?.description || {},
        tags: data?.tags?.map(tag => `${tag}`) || [],
        initialTags: data?.tags?.map(tag => `${tag}`) || [],
        location: data?.location || null,
        seoTitle: data?.seo?.seoTitle || {},
        seoDescription: data?.seo?.seoDescription || {},
        seoKeywords: data?.seo?.seoKeywords || {},
        eventDate: data?.dates?.eventDate || null,
        eventEndDate: data?.dates?.eventEndDate || null,
        modifiedDate: data?.dates?.modifiedDate || null,
        startPublicationDate: data?.dates?.startPublicationDate || null,
        endPublicationDate: data?.dates?.endPublicationDate || null,
        startEmbargoDate: data?.dates?.startEmbargoDate || null,
        endEmbargoDate: data?.dates?.endEmbargoDate || null,
        termsOfUse: data?.legalNotice?.termsOfUse || {},
        copyrightAuthor: data?.legalNotice?.copyrightAuthor || {},
        copyrightYear: data?.legalNotice?.copyrightYear || '',
        copyrightLicense: data?.legalNotice?.copyrightLicense || {},
        links: links || (emptyLinks ? [] : [
            {
                id: 0,
                ref: {},
                description: {}
            }
        ]),
        attachments: attachments ? transformAttachments(attachments) : [
            {
                id: 0,
                ref: {},
                description: {}
            }
        ],
        files: data?.mediaFiles ? transformFiles(data.mediaFiles) : [],
        owner: data?.owner || '',
        creationDate: data?.dates?.createDate || null,
        updateDate: data?.dates?.updateDate || null,
        transcript: data?.transcript || {},
        thumbnail: data?.thumbnail ? transformFiles(data.thumbnail) : [],
        multilanguageFiles: data?.infographicFiles ? transformInfographicFiles(data.infographicFiles) : {},
        coverage: data?.coverage || [],
        status: data?.status || null,
    };
}


export const transformFetchedTopic = (data) => {
    return {
        topicId: data.topicId,
        title: transformMultilanguageArrayToObject(data.titles),
        shortDescription: transformMultilanguageArrayToObject(data.shortDescriptions),
        description: transformMultilanguageArrayToObject(data.descriptions),
        tags: data?.tags?.map(tag => `${tag}`) || [],
        initialTags: data?.tags?.map(tag => `${tag}`) || [],
        seoTitle: transformMultilanguageArrayToObject(data.seoTitles),
        seoDescription: transformMultilanguageArrayToObject(data.shortDescriptions),
        startPublicationDate: data.startDate ?? null,
        endPublicationDate: data.endDate ?? null,
        links: data?.linkMetadatas?.length > 0
        ? transformLinks(data.attachmentMetadatas)
        : [
            {
                id: 0,
                ref: {},
                description: {}
            }
        ],
        attachments: data.attachmentMetadatas ?.length > 0
        ? transformLinks(data.attachmentMetadatas)
        : [
            {
                id: 0,
                ref: {},
                description: {}
            }
        ],
        creationDate: data.creationDate ?? null,
        editDate: data.updateDate ?? null,
        coverImage: data.coverImage ? {
            name: data.coverImage.split('/').pop(),
            url: data.coverImage,
            status: 'done',
            lang: 'en',
            uid: new Date().getTime(),
        } : null,
        files: data?.originalImage ? [
        {
            name: data.originalImage.split('/').pop(),
            url: data.originalImage,
            status: 'done',
            lang: 'en',
            uid: new Date().getTime(),
        }] : [],
    };
};
