import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';
import TagList from '@Components/BasicComponents/TagList';
import BasicInput from '@Components/BasicComponents/BasicInput';
import Validate from '@Components/Validate';

import { required, maxChars, languageRequired } from '@/utils/errors/list';

export default function MainInformation ({
	selector,
	hasShortDescription = false,
	hasTags = true,
	hasLocation = false,
	hasStatus = false,
	...otherProps
}) {
	const {
		title,
		description,
		tags,
		initialTags,
		shortDescription,
		setTags,
		setValue,
		countryCode,
		setCountryCode,
		location,
		status,
	} = selector();

	const handleChange = (param) => {
		return ({countryCode, value}) => setValue({param, countryCode, value});
	};

	return (
		<>
			<h3>Main Information</h3>
			<Validate requirements={[required]}>
				<MultiLanguageInput
					title="Title"
					value={title}
					onChange={handleChange('title')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					required
					tooltip="This is the main text of the media content that introduces or defines the media content in a general and summarised way."
					{...otherProps}
				/>
			</Validate>

			{hasShortDescription &&
				<>
					<br />
					<Validate requirements={[maxChars(500, {isWarning: true}), languageRequired('en_GB')]}>
						<MultiLanguageInput
							title="Short description (max. 500 characters)"
							textArea
							value={shortDescription}
							onChange={handleChange('shortDescription')}
							countryCode={countryCode}
							setCountryCode={setCountryCode}
							{...otherProps}
						/>
					</Validate>
				</>
			}

			<br />
			<Validate requirements={[maxChars(500, {isWarning: true}), languageRequired('en_GB')]}>
				<MultiLanguageInput
					title="Description"
					textArea
					value={description}
					onChange={handleChange('description')}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					tooltip="It is the secondary text of the media content that gives more detail of the media content in a more extensive way."
					{...otherProps}
				/>
			</Validate>

			{hasTags &&
				<>
					<br />
					<TagList
						title="Tags"
						value={tags}
						onChange={setTags}
						initialData={initialTags || []}
						tooltip="Tags are used to classify media content to highlight personalities, topics, locations, etc., in the Multimedia Centre."
						{...otherProps}
					/>
				</>
			}
			{hasLocation &&
				<>
					<br />
					<BasicInput
						title="Location"
						value={location}
						tooltip="The physical space where the meeting or event is to be held is defined."
						{...otherProps}
					/>
				</>
			}
			{hasStatus &&
				<>
					<br />
					<BasicInput
						title="Status"
						value={status}
						tooltip="The current broadcast status of the stream is defined."
						{...otherProps}
					/>
				</>
			}
		</>
	);
}