import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';


export default function Transcript ({selector}) {
	const {transcript, countryCode, setCountryCode, setValue} = selector();

	const handleChange = ({countryCode, value}) => setValue({param: 'transcript', countryCode, value});


	return (
		<>
			<h3>Transcript</h3>
			<MultiLanguageInput
				title="Description"
				value={transcript}
				onChange={handleChange}
				style={{marginVertical: 10}}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
				textArea
				tooltip="A text version of spoken content within the media, such as dialogue or narration, often used for accessibility and SEO purposes."
			/>
		</>
	);
}