import MainInformation from '@Components/ComposedComponents/MainInformation';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';
import InfographicUpload from '@Components/ComposedComponents/InfographicUpload';
import UploadButtons from '@Components/ComposedComponents/UploadButtons';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import CopyrightInformation from '@Components/ComposedComponents/CopyrightInformation';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import ZipUpload from '@Components/ComposedComponents/ZipUpload';



export default function InfographicForm ({interactive = false, ...props}) {
	return (
		<>
			<MainInformation {...props}/>
			<br/><br/>
			<SEOInformation {...props}/>
            <br/><br/>
            <PublishingDates {...props}/>
			<br/><br/>
			{ !interactive ?
				<>
					<CopyrightInformation {...props}/>
					<br/><br/>
					<LinksAndAttachments {...props}/>
					<br/><br/>
					<InfographicUpload {...props}/>
				</> :
				<ZipUpload {...props}/>
			}
			<br/><br/>
			<UploadButtons {...props}/>
		</>
	);
}