import { Space } from "antd";
import InfoTooltip from "./InfoTooltip";

export default function BasicLabel({title, required = false, tooltip = null}) {
  return (
    <Space>
      <label>
        {title}
        {required && '*'}
      </label>
      {tooltip && <InfoTooltip text={tooltip} />}
    </Space>
  );
}