import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData, deleteData } from '@/utils/api';
import { transformAudioListResults } from '@/utils/transformData/transformResults';

export const fetchMediaList = createAsyncThunk(
	'mediaList/mediaListLoading',

	async ({type = 'audio', pageSize = 20, page = 1, sort = 'mediaDate:desc', searchType = 'epId', search = ''}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: `/${type}s?pageSize=${pageSize}&page=${page}&sort=${sort}&${searchType}=${search}`});

				if (response.error) {
					return rejectWithValue(response);
				}

				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const deleteItem = createAsyncThunk(
	'mediaList/deleteItem',

	async ({epId, type = 'audio'}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				if (type === 'streaming') {
					type = 'streamings';
				}
				const response = await deleteData({auth, endpoint: `/${type}/${epId}`});

				if (response.error) {
					return rejectWithValue(response.message);
				}
				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);